// @flow

import find from 'lodash/find';
import get from 'lodash/get';
import {
  GOOGLE_TAGMANAGER_ACTIONS,
  gtmMailingListSubscription,
  gtmAuthLogin,
  gtmAuthRegister,
} from 'shared_data/providers/google/tagmanager/Actions';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { quiz as QUIZ_ENUM, analytics as ANALYTICS_ENUM } from 'Enum';
import { cookies as CONFIG_COOKIES, store as CONFIG_STORE, libs as CONFIG_LIBS } from 'Config';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { GTMService } from 'shared_services/riseart/GTM';
import { getLocaleConfig, extractLocaleFromUrl } from 'shared_services/riseart/utils/RouteUtils';
import {
  prepareGtmMe,
  prepareGtmArtwork,
  prepareGtmArtist,
  prepareGtmFavoriteArtwork,
  prepareGtmCartData,
  prepareGtmOrderData,
} from 'shared_services/redux/helpers/gtm';

export const GTM_EVENT_TRIGGER_REASONS = {
  registration: 'registration',
  login: 'login',
  subscription: 'subscription',
  updateRegional: 'updateRegional',
  updateUser: 'updateUser',
  updateSubscriptions: 'updateSubscriptions',
  passwordReset: 'passwordReset',
};

export const GTM_CHECKOUT_STEP_INDEXES = { begin: 0, info: 1, shipping: 2, billing: 3, review: 4 };

/**
 * MiddlewareGoogleTagManager
 */
export default class MiddlewareGoogleTagManager {
  // GTM event names
  static EVENT_APPLICATION_CONFIG = 'ra.applicationConfig';

  static EVENT_ACTION_PAGE_META_RESOLVED = 'ra.pageMetaResolved';

  static EVENT_PAGEVIEW = 'ra.pageview';

  static EVENT_MARKETING_CAMPAIGN_MATCHED = 'ra.marketingCampaignMatched';

  static EVENT_ME_UPDATED = 'ra.meUpdated';

  static EVENT_AUTH_REGISTRATION = 'ra.registration';

  static EVENT_AUTH_LOGIN = 'ra.login';

  static EVENT_FAVORITE_ADD = 'ra.favorite';

  static EVENT_FAVORITE_REMOVE = 'ra.unfavorite';

  static EVENT_FOLLOW = 'ra.follow';

  static EVENT_UNFOLLOW = 'ra.unfollow';

  static EVENT_SEARCH = 'ra.search';

  static EVENT_SHARE = 'ra.share';

  static EVENT_MAILING_LIST_SUBSCRIPTION = 'ra.subscription';

  static EVENT_QUIZ_STARTED = 'ra.quizStarted';

  static EVENT_QUIZ_QUESTION_LOAD = 'ra.quizQuestionLoaded';

  static EVENT_QUIZ_COMPLETED = 'ra.quizCompleted';

  static EVENT_QUIZ_RESULTS = 'ra.quizResults';

  static EVENT_QUIZ_TAKEN = 'ra.quizTaken';

  static EVENT_ARTIST_VIEW = 'ra.artistView';

  static EVENT_ARTIST_COMMISSION_OPEN = 'ra.artistCommissionOpen';

  static EVENT_ARTIST_COMMISSION_SEND = 'ra.artistCommissionSend';

  static EVENT_ARTWORK_VIEW = 'ra.artworkView';

  static EVENT_ARTWORK_LIST_VIEW = 'ra.artworkListView';

  static EVENT_ARTWORK_LIST_ITEM_SELECTED = 'ra.artworkListItemSelected';

  static EVENT_ARTWORK_ENQUIRE_OPEN = 'ra.artworkEnquireOpen';

  static EVENT_ARTWORK_ENQUIRE_SEND = 'ra.artworkEnquireSend';

  static EVENT_ARTWORK_OFFER_OPEN = 'ra.artworkOfferOpen';

  static EVENT_ARTWORK_OFFER_SEND = 'ra.artworkOfferSend';

  static EVENT_CMS_ENQUIRE_SEND = 'ra.cmsEnquireSend';

  static EVENT_CART_VIEW = 'ra.cartView';

  static EVENT_CART_ITEM_ADD = 'ra.addToCart';

  static EVENT_CART_ITEM_REMOVE = 'ra.removeFromCart';

  static EVENT_CART_ITEM_UPDATED = 'ra.cartItemUpdated';

  static EVENT_CART_CREDIT_USE = 'ra.cartCreditUse';

  static EVENT_CART_CREDIT_REMOVE = 'ra.cartCreditRemove';

  static EVENT_CART_COUPON_USE = 'ra.cartCouponUse';

  static EVENT_CART_COUPON_REMOVE = 'ra.cartCouponRemove';

  static EVENT_CHECKOUT_STEP_BEGIN = 'ra.beginCheckout';

  static EVENT_CHECKOUT_STEP_CONTACT = 'ra.addContactInfo';

  static EVENT_CHECKOUT_STEP_SHIPPING = 'ra.addShippingInfo';

  static EVENT_CHECKOUT_STEP_PAYMENT = 'ra.addPaymentInfo';

  static EVENT_PROMO_CARD_SHOW = 'ra.promotionalCardShow';

  static EVENT_PROMO_CARD_CLICK = 'ra.promotionalCardClick';

  static EVENT_PURCHASE = 'ra.purchase';

  static EVENT_RSVP = 'ra.eventRsvp';

  static EVENT_RECENT_WIDGET_SHOW = 'ra.recentWidgetShow';

  static EVENT_RECENT_WIDGET_CLOSE = 'ra.recentWidgetClose';

  static EVENT_VISUAL_SEARCH_ADD = 'ra.visualSearchAdd';

  static EVENT_VISUAL_SEARCH_REFRESH = 'ra.visualSearchRefresh';

  static EVENT_VISUAL_SEARCH_RESTART = 'ra.visualSearchRestart';

  static EVENT_VISUAL_SEARCH_MENU_BACK = 'ra.visualSearchMenuBack';

  static EVENT_VISUAL_SEARCH_MENU_DETAILS = 'ra.visualSearchMenuDetails';

  static EVENT_VISUAL_SEARCH_MENU_CART = 'ra.visualSearchCart';

  static EVENT_VISUAL_SEARCH_MENU_WISHLIST = 'ra.visualSearchWishlist';

  static EVENT_LENS_SEARCH_START = 'ra.lensSearchStart';

  static EVENT_LENS_SEARCH_UPLOAD_START = 'ra.lensSearchUploadStart'; // send file upload data (key: 'uploadTransaction: {id, progress, stage, status, files}')

  static EVENT_LENS_SEARCH_UPLOAD_COMPLETE = 'ra.lensSearchUploadComplete'; // send file upload data (key: uploadTransaction: {id, progress, stage, status, files})

  static EVENT_LENS_SEARCH_CAMERA_OPEN = 'ra.lensSearchCameraOpen';

  static EVENT_LENS_SEARCH_CAMERA_CAPTURE = 'ra.lensSearchCameraCapture';

  static EVENT_LENS_SEARCH_EXECUTE = 'ra.lensSearchExecute'; // send image upload data (key: 'lensImage')

  static EVENT_ELEMENT_CLICKED = 'ra.elementClicked';

  static EVENT_ARTWORK_DETAIL_TAB_CLOSE = 'ra.artDetailTabClose';

  static EVENT_ARTWORK_DETAIL_TAB_OPEN = 'ra.artDetailTabOpen';

  static EVENT_FAQ_TAB_CLOSE = 'ra.faqTabClose';

  static EVENT_FAQ_TAB_OPEN = 'ra.faqTabOpen';

  static visualSearchTriggers = {
    artwork_detail: 'artwork_detail',
    artwork_category: 'artwork_category',
  };

  static triggerReasonToEvent = {
    [GTM_EVENT_TRIGGER_REASONS.registration]: gtmAuthRegister,
    [GTM_EVENT_TRIGGER_REASONS.login]: gtmAuthLogin,
    [GTM_EVENT_TRIGGER_REASONS.subscription]: gtmMailingListSubscription,
  };

  static checkoutStepIndexToEvent = {
    0: MiddlewareGoogleTagManager.EVENT_CHECKOUT_STEP_BEGIN,
    1: MiddlewareGoogleTagManager.EVENT_CHECKOUT_STEP_CONTACT,
    2: MiddlewareGoogleTagManager.EVENT_CHECKOUT_STEP_SHIPPING,
    3: MiddlewareGoogleTagManager.EVENT_CHECKOUT_STEP_PAYMENT,
  };

  /**
   * Redux Middleware
   *
   * @param store
   */
  static middleware = (store) => (next) => (action) => {
    switch (action.type) {
      // Application core events
      case GOOGLE_TAGMANAGER_ACTIONS.PAGEVIEW:
        MiddlewareGoogleTagManager.pageviewEvent(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.APPLICATION_PROPERTY_UPDATE:
        if (!action) {
          return;
        }

        switch (get(action, 'payload.payload.property')) {
          case 'locale':
            MiddlewareGoogleTagManager.applicationPropertyUpdate(
              MiddlewareGoogleTagManager.EVENT_APPLICATION_CONFIG,
              { [action.payload.payload.property]: get(action, 'payload.payload.value.name') },
            );
            break;
          default:
            break;
        }
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.PAGE_META_RESOLVED:
        MiddlewareGoogleTagManager.pushPageMetaResolvedEvent(
          MiddlewareGoogleTagManager.EVENT_ACTION_PAGE_META_RESOLVED,
          get(action, 'payload.payload'),
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.MARKETING_CAMPAIGN_MATCHED:
        MiddlewareGoogleTagManager.marketingCampaignMatched(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.BROWSER_TAB_OPEN:
      case GOOGLE_TAGMANAGER_ACTIONS.BROWSER_TAB_CLOSE:
        MiddlewareGoogleTagManager.browserTabToggleHandler(action);
        break;

      // Authentication
      case GOOGLE_TAGMANAGER_ACTIONS.ME_UPDATED:
        MiddlewareGoogleTagManager.meUpdated(action.payload, store);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.AUTH_LOGIN:
        MiddlewareGoogleTagManager.pushAuthEvent(
          MiddlewareGoogleTagManager.EVENT_AUTH_LOGIN,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.AUTH_REGISTER:
        MiddlewareGoogleTagManager.pushAuthEvent(
          MiddlewareGoogleTagManager.EVENT_AUTH_REGISTRATION,
          action.payload,
        );
        break;

      // Favorites
      case GOOGLE_TAGMANAGER_ACTIONS.FAVORITE_ADD:
        MiddlewareGoogleTagManager.sendFavoriteEvent(
          MiddlewareGoogleTagManager.EVENT_FAVORITE_ADD,
          MiddlewareGoogleTagManager.getMe(store),
          localeSelector(store),
          action.payload.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.FAVORITE_REMOVE:
        MiddlewareGoogleTagManager.sendFavoriteEvent(
          MiddlewareGoogleTagManager.EVENT_FAVORITE_REMOVE,
          MiddlewareGoogleTagManager.getMe(store),
          localeSelector(store),
          action.payload.payload,
        );
        break;

      // Follow
      case GOOGLE_TAGMANAGER_ACTIONS.FOLLOW_CREATE:
        MiddlewareGoogleTagManager.toggleFollow(
          MiddlewareGoogleTagManager.EVENT_FOLLOW,
          get(action, 'payload.payload'),
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.FOLLOW_DELETE:
        MiddlewareGoogleTagManager.toggleFollow(
          MiddlewareGoogleTagManager.EVENT_UNFOLLOW,
          get(action, 'payload.payload'),
        );
        break;

      // Search
      case GOOGLE_TAGMANAGER_ACTIONS.SEARCH_TEXT:
        MiddlewareGoogleTagManager.search(MiddlewareGoogleTagManager.EVENT_SEARCH, action);
        break;

      // Mailing list
      case GOOGLE_TAGMANAGER_ACTIONS.MAILING_LIST_SUBSCRIPTION:
        MiddlewareGoogleTagManager.mailingListSubscription(
          MiddlewareGoogleTagManager.getMe(store),
          action.payload && action.payload.auth,
        );
        break;

      // Filters
      case GOOGLE_TAGMANAGER_ACTIONS.ADD_FILTER:
      case GOOGLE_TAGMANAGER_ACTIONS.REMOVE_FILTER:
        MiddlewareGoogleTagManager.filterEvent(action);
        break;

      // Artist
      case GOOGLE_TAGMANAGER_ACTIONS.ARTIST_VIEW:
        MiddlewareGoogleTagManager.artistViewHandler(action);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTIST_COMMISSION_OPEN:
        MiddlewareGoogleTagManager.artistCommissionFormEvent(
          MiddlewareGoogleTagManager.EVENT_ARTIST_COMMISSION_OPEN,
          action,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTIST_COMMISSION_SEND:
        MiddlewareGoogleTagManager.artistCommissionFormEvent(
          MiddlewareGoogleTagManager.EVENT_ARTIST_COMMISSION_SEND,
          action && action.payload,
        );
        break;

      // Artwork
      case GOOGLE_TAGMANAGER_ACTIONS.ART_VIEW:
        MiddlewareGoogleTagManager.artViewHandler(action);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_ENQUIRE_OPEN:
        MiddlewareGoogleTagManager.artworkFormEvent(
          MiddlewareGoogleTagManager.EVENT_ARTWORK_ENQUIRE_OPEN,
          'enquire',
          action,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_OFFER_OPEN:
        MiddlewareGoogleTagManager.artworkFormEvent(
          MiddlewareGoogleTagManager.EVENT_ARTWORK_OFFER_OPEN,
          'offer',
          action,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_ENQUIRE_SEND:
        MiddlewareGoogleTagManager.artworkFormEvent(
          MiddlewareGoogleTagManager.EVENT_ARTWORK_ENQUIRE_SEND,
          'enquire',
          action && action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_OFFER_SEND:
        MiddlewareGoogleTagManager.artworkFormEvent(
          MiddlewareGoogleTagManager.EVENT_ARTWORK_OFFER_SEND,
          'offer',
          action && action.payload,
        );
        break;

      // Artwork List
      case GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_LIST_VIEW:
        // Reset list and artworks fields before update
        GTMService.push({ list: null, artworks: null });

        GTMService.push({
          event: MiddlewareGoogleTagManager.EVENT_ARTWORK_LIST_VIEW,
          list: { name: action.payload.name, location: action.payload.location },
          artworks:
            (action.payload &&
              action.payload.items &&
              action.payload.items.map(prepareGtmArtwork)) ||
            null,
        });
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.ARTWORK_LIST_ITEM_SELECTED:
        GTMService.push({
          event: MiddlewareGoogleTagManager.EVENT_ARTWORK_LIST_ITEM_SELECTED,
          list: { name: action.payload.name, location: action.payload.location },
          artwork: prepareGtmArtwork(action.payload.artwork),
        });
        break;

      // CMS enquire form send
      case GOOGLE_TAGMANAGER_ACTIONS.CMS_ENQUIRE_SEND:
        MiddlewareGoogleTagManager.cmsFormEvent(
          MiddlewareGoogleTagManager.EVENT_CMS_ENQUIRE_SEND,
          'cmsEnquire',
          action && action.payload,
        );
        break;

      // Quiz
      case GOOGLE_TAGMANAGER_ACTIONS.QUIZ_CLICK_START:
        MiddlewareGoogleTagManager.pushQuizEvent(MiddlewareGoogleTagManager.EVENT_QUIZ_STARTED, {
          start: action.payload || null,
          completed: false,
        });
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.QUIZ_QUESTION_LOADED:
        MiddlewareGoogleTagManager.pushQuizEvent(
          MiddlewareGoogleTagManager.EVENT_QUIZ_QUESTION_LOAD,
          { question: action.payload || null },
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.QUIZ_COMPLETED:
        MiddlewareGoogleTagManager.pushQuizEvent(MiddlewareGoogleTagManager.EVENT_QUIZ_COMPLETED, {
          completed: true,
        });
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.QUIZ_RESULT_LOADED:
        MiddlewareGoogleTagManager.pushQuizEvent(MiddlewareGoogleTagManager.EVENT_QUIZ_RESULTS, {
          profile: action.payload || null,
        });
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.QUIZ_TAKEN:
        MiddlewareGoogleTagManager.quizTaken(action);
        break;

      // Cart
      case GOOGLE_TAGMANAGER_ACTIONS.CART_VIEW:
        MiddlewareGoogleTagManager.cartView(action);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_ITEM_ADD:
        MiddlewareGoogleTagManager.addToCart(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_ITEM_UPDATE:
        MiddlewareGoogleTagManager.cartItemUpdated(
          MiddlewareGoogleTagManager.EVENT_CART_ITEM_UPDATED,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_ITEM_REMOVE:
        MiddlewareGoogleTagManager.removeFromCart(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_CREDIT_USE:
        MiddlewareGoogleTagManager.toggleCartCredit(
          MiddlewareGoogleTagManager.EVENT_CART_CREDIT_USE,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_CREDIT_REMOVE:
        MiddlewareGoogleTagManager.toggleCartCredit(
          MiddlewareGoogleTagManager.EVENT_CART_CREDIT_REMOVE,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_COUPON_USE:
        MiddlewareGoogleTagManager.toggleCartCoupon(
          MiddlewareGoogleTagManager.EVENT_CART_COUPON_USE,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CART_COUPON_REMOVE:
        MiddlewareGoogleTagManager.toggleCartCoupon(
          MiddlewareGoogleTagManager.EVENT_CART_COUPON_REMOVE,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.EVENT_ELEMENT_CLICKED: {
        const {
          location = null,
          action: actionField = null,
          objectType = null,
          objectUrl = null,
        } = action.payload || {};

        GTMService.push({
          event: MiddlewareGoogleTagManager.EVENT_ELEMENT_CLICKED,
          clickedElement: { location, action: actionField, objectType, objectUrl },
        });
        break;
      }

      // Checkout
      case GOOGLE_TAGMANAGER_ACTIONS.CHECKOUT_PURCHASE:
        MiddlewareGoogleTagManager.purchase(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CHECKOUT_STEP_ENTER:
        MiddlewareGoogleTagManager.checkoutStepEnter(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CHECKOUT_STEP_UPDATE:
        MiddlewareGoogleTagManager.checkoutStepUpdate(action.payload);
        break;

      // Events
      case GOOGLE_TAGMANAGER_ACTIONS.EVENT_RSVP:
        MiddlewareGoogleTagManager.eventRsvp(get(action, 'payload.payload'));
        break;

      // Social
      case GOOGLE_TAGMANAGER_ACTIONS.SOCIAL_SHARE: {
        const { objectType = null, platform = null, objectUrl = null } = action.payload || {};

        GTMService.push({
          event: MiddlewareGoogleTagManager.EVENT_SHARE,
          share: { objectType, objectUrl, platform },
        });
        break;
      }
      // Recent Artworks Widget Actions
      case GOOGLE_TAGMANAGER_ACTIONS.RECENT_WIDGET_TOGGLE: {
        const isOpened = get(action, 'payload.payload.value.opened', false);
        MiddlewareGoogleTagManager.pushRecentWidgetEvent(
          isOpened
            ? MiddlewareGoogleTagManager.EVENT_RECENT_WIDGET_SHOW
            : MiddlewareGoogleTagManager.EVENT_RECENT_WIDGET_CLOSE,
        );
        break;
      }

      // Visual search
      case GOOGLE_TAGMANAGER_ACTIONS.VISUAL_SEARCH_ADD:
        MiddlewareGoogleTagManager.visualSearchAction(
          MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_ADD,
          action.payload.artwork,
          action.payload.trigger || null,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.VISUAL_SEARCH_REFRESH:
        MiddlewareGoogleTagManager.visualSearchAction(
          MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_REFRESH,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.VISUAL_SEARCH_RESTART:
        MiddlewareGoogleTagManager.visualSearchAction(
          MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_RESTART,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.VISUAL_SEARCH_MENU_CLICK: {
        const VS_ACTIONS_TO_GTM_EVENT = {
          backToResults: MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_MENU_BACK,
          details: MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_MENU_DETAILS,
          addToCart: MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_MENU_CART,
          addToWishlist: MiddlewareGoogleTagManager.EVENT_VISUAL_SEARCH_MENU_WISHLIST,
        };
        MiddlewareGoogleTagManager.visualSearchAction(
          VS_ACTIONS_TO_GTM_EVENT[action && action.payload.button],
          action.payload.artwork,
        );
        break;
      }

      // Lens Search
      case GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_START:
        MiddlewareGoogleTagManager.lensSearchAction(
          MiddlewareGoogleTagManager.EVENT_LENS_SEARCH_START,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_UPLOAD_START:
        MiddlewareGoogleTagManager.lensSearchAction(
          MiddlewareGoogleTagManager.EVENT_LENS_SEARCH_UPLOAD_START,
          { uploadTransaction: action.payload },
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_UPLOAD_COMPLETE:
        MiddlewareGoogleTagManager.lensSearchAction(
          MiddlewareGoogleTagManager.EVENT_LENS_SEARCH_UPLOAD_COMPLETE,
          { uploadTransaction: action.payload },
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_CAMERA_OPEN:
        MiddlewareGoogleTagManager.lensSearchAction(
          MiddlewareGoogleTagManager.EVENT_LENS_SEARCH_CAMERA_OPEN,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_CAMERA_CAPTURE:
        MiddlewareGoogleTagManager.lensSearchAction(
          MiddlewareGoogleTagManager.EVENT_LENS_SEARCH_CAMERA_CAPTURE,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.LENS_SEARCH_SEARCH_EXECUTE:
        MiddlewareGoogleTagManager.lensSearchAction(
          MiddlewareGoogleTagManager.EVENT_LENS_SEARCH_EXECUTE,
          { lensImage: action.payload },
        );
        break;

      // Component events
      case GOOGLE_TAGMANAGER_ACTIONS.ART_TAB_DETAIL_TOGGLE: {
        const { openedTabs, tabIdx, tabId } = action.payload || {};

        GTMService.push({
          event:
            openedTabs && openedTabs.indexOf(tabIdx) > -1
              ? MiddlewareGoogleTagManager.EVENT_ARTWORK_DETAIL_TAB_CLOSE
              : MiddlewareGoogleTagManager.EVENT_ARTWORK_DETAIL_TAB_OPEN,
          artTabDetail: {
            index: Number.isNaN(parseInt(tabIdx, 10)) ? null : tabIdx,
            title: tabId || null,
          },
        });
        break;
      }

      case GOOGLE_TAGMANAGER_ACTIONS.ART_TAB_FAQ_TOGGLE:
      case GOOGLE_TAGMANAGER_ACTIONS.CART_TAB_FAQ_TOGGLE: {
        const ACTION_TO_LOCATION = {
          [GOOGLE_TAGMANAGER_ACTIONS.ART_TAB_FAQ_TOGGLE]: ANALYTICS_ENUM.location.art,
          [GOOGLE_TAGMANAGER_ACTIONS.CART_TAB_FAQ_TOGGLE]: ANALYTICS_ENUM.location.cart,
        };
        const { openedTabs, tabId } = action.payload || {};

        GTMService.push({
          event:
            openedTabs.indexOf(action.payload.tabIdx) > -1
              ? MiddlewareGoogleTagManager.EVENT_FAQ_TAB_CLOSE
              : MiddlewareGoogleTagManager.EVENT_FAQ_TAB_OPEN,
          faq: {
            location: ACTION_TO_LOCATION[action.type] || null,
            question: tabId || null,
          },
        });
        break;
      }
      case GOOGLE_TAGMANAGER_ACTIONS.PROMO_CARD_SHOW:
        MiddlewareGoogleTagManager.pushPromoCardEvent(
          MiddlewareGoogleTagManager.EVENT_PROMO_CARD_SHOW,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.PROMO_CARD_CLICK:
        MiddlewareGoogleTagManager.pushPromoCardEvent(
          MiddlewareGoogleTagManager.EVENT_PROMO_CARD_CLICK,
          action.payload,
        );
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CONSENT_REJECT:
        MiddlewareGoogleTagManager.setConsentState(action.payload);
        break;
      case GOOGLE_TAGMANAGER_ACTIONS.CONSENT_ACCEPT:
        MiddlewareGoogleTagManager.setConsentState(action.payload);
        break;
      default:
        break;
    }
    next(action);
  };

  /**
   * resetField
   *
   * @param {string} data
   * @param {any} initialData
   */
  static resetField(field: string, initialData?: any = null) {
    // Clear the previous ecommerce object
    GTMService.push({ [field]: initialData });
  }

  static pushPromoCardEvent(event: string, payload: Object) {
    GTMService.push({ event, promotionalCard: payload });
  }

  /**
   * pushRecentWidgetEvent
   *
   * @param {string} event
   * @returns {void}
   */
  static pushRecentWidgetEvent(event: string): void {
    GTMService.push({ event });
  }

  /**
   * pushPageMetaResolvedEvent
   *
   * @param {string} event
   * @param {Object} payload
   * @returns {void}
   */
  static pushPageMetaResolvedEvent(event: string, payload: Object): void {
    GTMService.push({ event, pageMeta: payload && payload.pageMeta });
  }

  /**
   * cartView
   *
   * @param {Object} action
   */
  static cartView(action: Object = {}) {
    if (!action || !action.payload || !action.payload.cart) {
      return;
    }

    MiddlewareGoogleTagManager.resetField('cart');

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_CART_VIEW,
      cart: prepareGtmCartData({
        ...action.payload.cart,
        cartItems: action.payload.cartItems,
      }),
    });
  }

  /**
   * addToCart
   *
   * @param {Object} action
   */
  static addToCart(action: Object = {}) {
    if (!action || !action.payload || !action.payload.cartItems || !action.payload.cart) {
      return;
    }

    MiddlewareGoogleTagManager.resetField('cart');

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_CART_ITEM_ADD,
      cart: prepareGtmCartData({
        ...action.payload.cart,
        cartItems: action.payload.cartItems,
        modifiedItem: action.payload.modifiedItem && action.payload.modifiedItem[0],
      }),
    });
  }

  /**
   * removeFromCart
   *
   * @param {Object} action
   */
  static removeFromCart(action: Object = {}) {
    if (!action || !action.payload || !action.payload.cartItems || !action.payload.cart) {
      return;
    }

    MiddlewareGoogleTagManager.resetField('cart');

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_CART_ITEM_REMOVE,
      cart: prepareGtmCartData({
        ...action.payload.cart,
        cartItems: action.payload.cartItems,
        modifiedItem: action.payload.modifiedItem && action.payload.modifiedItem[0],
      }),
    });
  }

  /**
   * checkoutStepEnter
   *
   * @param {Object} action
   */
  static checkoutStepEnter(action: Object = {}) {
    if (!action || !action.payload || [undefined, null].indexOf(action.payload.step) > -1) {
      return;
    }

    const { step, cart, cartItems, selectedPaymentCard, enteredCardType } = action.payload;
    const eventByStep = MiddlewareGoogleTagManager.checkoutStepIndexToEvent[step];

    if (eventByStep === MiddlewareGoogleTagManager.EVENT_CHECKOUT_STEP_BEGIN) {
      MiddlewareGoogleTagManager.resetField('cart');

      GTMService.push({
        event: eventByStep,
        cart: prepareGtmCartData({ ...cart, cartItems, selectedPaymentCard, enteredCardType }),
      });
    }
  }

  /**
   * checkoutStepUpdate
   *
   * @param {Object} action
   */
  static checkoutStepUpdate(action: Object = {}) {
    if (!action || !action.payload || [undefined, null].indexOf(action.payload.step) > -1) {
      return;
    }

    const { step, cart, cartItems, selectedPaymentCard, enteredCardType } = action.payload;
    const eventByStep = MiddlewareGoogleTagManager.checkoutStepIndexToEvent[step];

    if (eventByStep) {
      MiddlewareGoogleTagManager.resetField('cart');

      GTMService.push({
        event: eventByStep,
        cart: prepareGtmCartData({ ...cart, cartItems, selectedPaymentCard, enteredCardType }),
      });
    }
  }

  /**
   * purchase
   *
   * @param {Object} action
   */
  static purchase(action: Object = {}) {
    if (!action || !action.payload || !action.payload.order) {
      return;
    }

    const { order } = action.payload;

    MiddlewareGoogleTagManager.resetField('order');

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_PURCHASE,
      order: prepareGtmOrderData(order),
    });
  }

  /**
   * cartItemUpdated
   *
   * @param {string} event
   * @param {Object} action
   */
  static cartItemUpdated(event: string, action: Object = {}) {
    if (!event || !action || !action.payload || !action.payload.cartItems || !action.payload.cart) {
      return;
    }

    MiddlewareGoogleTagManager.resetField('cart');

    GTMService.push({
      event,
      cart: prepareGtmCartData({
        ...action.payload.cart,
        cartItems: action.payload.cartItems,
        modifiedItem: action.payload.modifiedItem && action.payload.modifiedItem[0],
      }),
    });
  }

  /**
   * toggleCartCoupon
   *
   * @param {string} event
   * @param {Object} action
   */
  static toggleCartCoupon(event: string, action: Object = {}) {
    if (!event || !action || !action.payload) {
      return;
    }

    MiddlewareGoogleTagManager.resetField('cart');

    GTMService.push({
      event,
      cart: prepareGtmCartData(action.payload),
    });
  }

  /**
   * toggleCartCredit
   *
   * @param {string} event
   * @param {Object} action
   */
  static toggleCartCredit(event: string, action: Object = {}) {
    if (!event || !action || !action.payload) {
      return;
    }

    MiddlewareGoogleTagManager.resetField('cart');

    GTMService.push({
      event,
      cart: prepareGtmCartData(action.payload),
    });
  }

  /**
   * FilterEvent
   *
   * @param action
   */
  static filterEvent(action) {
    if (!action || !action.payload) {
      return;
    }

    const { actionName, dimension, domain, value } = action.payload;

    if (dimension !== null && domain !== null && value !== null) {
      const event = {
        event: actionName,
        filter: {
          dimension,
          domain,
          value,
        },
      };
      GTMService.push(event);
    }
  }

  /**
   * GetMe
   *
   * @param store
   */
  static getMe(store) {
    return store.getState()[CONFIG_STORE.keys.me].data;
  }

  /**
   * sendFavoriteEvent
   *
   * @param eventName
   * @param me
   * @param payload
   */
  static sendFavoriteEvent(eventName, me, locale, payload) {
    if (!me || !payload) {
      return;
    }

    GTMService.push({ event: eventName, artwork: prepareGtmFavoriteArtwork(payload) });
  }

  /**
   * toggleFollow
   *
   * @param {string} event
   * @param {Object} action
   */
  static toggleFollow(event: string, payload: Object = {}) {
    if (!event || !payload) {
      return;
    }

    GTMService.push({ event, artist: prepareGtmArtist(payload) });
  }

  /**
   * MailingListSubscription
   *
   * @param me
   * @param action
   */
  static mailingListSubscription(me, payload) {
    if (me && payload) {
      const { emailAddress, type, visitorRole, location } = payload;
      const visitor = {
        role: visitorRole || me.visitor.role,
        email: me.visitor.email || emailAddress,
      };
      const gtmEventData = {
        location,
        mailing: {
          list: type,
          email: emailAddress,
        },
        ...(visitorRole || !me.visitor.email ? { visitor } : {}),
        event: MiddlewareGoogleTagManager.EVENT_MAILING_LIST_SUBSCRIPTION,
      };
      GTMService.push(gtmEventData);
    }
  }

  /**
   * browserTabToggleHandler
   *
   * @param {Object} payload
   * @returns {void}
   */
  static browserTabToggleHandler(action): void {
    if (action && action.payload) {
      const { actionName, target, referrer, url } = action.payload;

      GTMService.push({
        tab: { target, referrer, url },
        event: actionName,
      });
    }
  }

  /**
   * artViewHandler
   *
   * @param {Object} action
   */
  static artViewHandler(action: Object): void {
    if (action.payload) {
      const artwork = prepareGtmArtwork(action.payload.artData, action.payload.store);

      GTMService.push({
        event: MiddlewareGoogleTagManager.EVENT_ARTWORK_VIEW,
        artwork,
      });
    }
  }

  /**
   * artistViewHandler
   *
   * @param {Object} action
   */
  static artistViewHandler(action: Object): void {
    if (action.payload) {
      GTMService.push({
        event: MiddlewareGoogleTagManager.EVENT_ARTIST_VIEW,
        artist: prepareGtmArtist(action.payload),
      });
    }
  }

  /**
   * artworkFormEvent
   *
   * @param {string} event
   * @param {string} fieldName
   * @param {Object} action
   */
  static artworkFormEvent(event: string, fieldName: string, action: Object): void {
    if (!event || !fieldName || !action.payload) {
      return;
    }

    const { artwork, ...rest } = action.payload;

    GTMService.push({ event, [fieldName]: rest, artwork: prepareGtmArtwork(artwork) });
  }

  /**
   * artistCommissionFormEvent
   *
   * @param {string} event
   * @param {Object} action
   */
  static artistCommissionFormEvent(event: string, action: Object): void {
    if (!event || !action.payload) {
      return;
    }

    const { artist, ...rest } = action.payload;

    GTMService.push({
      event,
      artist: prepareGtmArtist(artist),
      commission: Object.keys(rest).length ? rest : null,
    });
  }

  /**
   * cmsFormEvent
   *
   * @param {string} event
   * @param {string} fieldName
   * @param {Object} action
   */
  static cmsFormEvent(event: string, fieldName: string, action: Object): void {
    if (!event || !fieldName || !action.payload) {
      return;
    }

    GTMService.push({ event, [fieldName]: action.payload });
  }

  /**
   * pushQuizEvent
   * @param {Object} eventPayload
   */
  static pushQuizEvent(event, payload = {}) {
    GTMService.push({ event, quiz: payload });
  }

  /**
   * quizTaken
   *
   * @param {Object} action
   */
  static quizTaken(action) {
    if (action.payload) {
      const { profile, answers, created } = action.payload;
      const genderQuestion = find(
        answers,
        (answer) => answer.questionDataType === QUIZ_ENUM.question.dataType.DATA_TYPE_DEMO_GENDER,
      );
      const ageQuestion = find(
        answers,
        (answer) => answer.questionDataType === QUIZ_ENUM.question.dataType.DATA_TYPE_DEMO_AGE,
      );

      const quizTakenData = {
        event: MiddlewareGoogleTagManager.EVENT_QUIZ_TAKEN,
        quiz: {
          created,
          profile: profile.code,
          gender: genderQuestion && genderQuestion.optionName ? genderQuestion.optionName : null,
          age: ageQuestion ? ageQuestion.valueData : null,
          answers: answers
            ? answers.map(
                ({
                  questionId,
                  questionText,
                  questionType,
                  valueData,
                  optionType,
                  optionName,
                }) => ({
                  questionId,
                  questionText,
                  optionType,
                  optionText:
                    questionType === QUIZ_ENUM.question.type.TYPE_TEXT ||
                    questionType === QUIZ_ENUM.question.type.TYPE_CURRENCY
                      ? valueData
                      : optionName,
                }),
              )
            : null,
        },
      };
      GTMService.push(quizTakenData);
    }
  }

  /**
   * pageviewEvent
   *
   * @param {Object} payload
   */
  static pageviewEvent(payload) {
    if (!payload) {
      return;
    }

    const locale =
      (payload.pathname && extractLocaleFromUrl(payload.pathname)) ||
      getLocaleConfig(true, 'isDefault');

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_PAGEVIEW,
      page: {
        ...payload,
        locale: locale && locale.name,
        language: locale && locale.language,
      },
    });
  }

  /**
   * meUpdated
   *
   * @param {Object} data
   */
  static meUpdated(data = {}, store = null) {
    const { identity, visitor } = prepareGtmMe(data.payload);
    const { trigger } = data.payload;
    const postEvent = MiddlewareGoogleTagManager.triggerReasonToEvent[trigger && trigger.reason];

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_ME_UPDATED,
      eventTriggerReason: (trigger && trigger.reason) || null,
      identity,
      visitor,
    });

    if (typeof postEvent === 'function' && store) {
      const authDomain = get(store.getState()[CONFIG_STORE.keys.auth], 'data.payload.auth_domain');

      store.dispatch(
        postEvent({
          auth: {
            ...trigger.data,
            domain: (authDomain && ANALYTICS_ENUM.authDomain[authDomain]) || null,
          },
        }),
      );
    }
  }

  /**
   * pushAuthEvent
   * @param {string} event
   * @param {?Object} data
   */
  static pushAuthEvent(event: string, payload: ?Object = null) {
    if (!event) {
      return;
    }

    GTMService.push({ event, ...payload });
  }

  /**
   * marketingCampaignDetected
   *
   * @param {Object} data
   */
  static marketingCampaignMatched(data = {}) {
    if (!data) {
      return;
    }

    const { id, code, type, name, startDate, endDate, description } = data.payload;

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_MARKETING_CAMPAIGN_MATCHED,
      marketingCampaign: {
        id,
        code,
        type,
        name,
        startDate,
        endDate,
        description,
      },
    });
  }

  /**
   * applicationPropertyUpdate
   *
   * @param {string} event
   * @param {Object} data
   */
  static applicationPropertyUpdate(event: string, data: Object) {
    GTMService.push({
      event,
      applicationConfig: data,
    });
  }

  /**
   * eventRsvp
   *
   * @param {Object} payload
   * @returns {void}
   */
  static eventRsvp(payload: Object = {}): void {
    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_RSVP,
      eventRsvp: {
        rsvpType: payload.selectedStatus,
        ...(payload.event
          ? {
              eventId: payload.event.id,
              eventName: payload.event.name,
              eventCountry: payload.event.venueCountryCode,
              eventCity: payload.event.venueCity,
              eventStartDate: payload.event.startDate,
              eventEndDate: payload.event.endDate,
              eventType: payload.event.type,
            }
          : null),
      },
    });
  }

  /**
   * visualSearchAction
   *
   * @param {string} event
   * @param {Object} artwork?
   * @param {string} trigger?
   */
  static visualSearchAction(event: string, artwork?: Object = null, trigger?: string = null) {
    GTMService.push({
      event,
      artwork: prepareGtmArtwork(artwork),
      visualSearch: { trigger },
    });
  }

  /**
   * lensSearchAction
   *
   * @param {string} event
   * @param {Object} artwork?
   * @param {string} trigger?
   */
  static lensSearchAction(event: string, payload: Object) {
    const { uploadTransaction: payloadUploadTransaction, lensImage: payloadLensImage } =
      payload || {};

    // Upload transaction data
    let uploadTransaction = null;

    if (payloadUploadTransaction) {
      const data = get(payloadUploadTransaction, 'files[0]') || null;

      if (data) {
        uploadTransaction = {
          id: data.id,
          progress: data.progress,
          stage: data.stage,
          status: data.status,
          files: data.fileIds,
        };
      }
    }

    // Lens image data
    let lensImage = null;

    if (payloadLensImage) {
      const { signedUri, ...restData } = payloadLensImage;
      lensImage = restData;
    }

    GTMService.push({
      event,
      ...(uploadTransaction ? { uploadTransaction } : null),
      ...(lensImage ? { lensImage } : null),
    });
  }

  /**
   * search
   *
   * @param {string} event
   * @param {string} fieldName
   * @param {Object} action
   */
  static search(event: string, action: Object): void {
    if (!event || !action.payload) {
      return;
    }

    GTMService.push({
      event,
      search: { domain: action.payload.domain || null, query: action.payload.query },
    });
  }

  /**
   * setConsentState
   *
   * @param {Object} payload
   * @returns {void}
   */
  static setConsentState(payload: Object) {
    if (!payload) {
      return;
    }

    // Load consent state from config
    const consentState = CONFIG_LIBS.google.consent.state[payload.state];

    if (!consentState) {
      return;
    }

    // Set consent cookie
    Cookies.set(CONFIG_COOKIES.consent.name, JSON.stringify(consentState), CONFIG_COOKIES.consent);

    // Call all registered consent listeners
    if (
      window &&
      window.riseartConsentListenersStack &&
      window.riseartConsentListenersStack.length > 0
    ) {
      window.riseartConsentListenersStack.forEach((listener) => listener(consentState));
    }
  }
}
